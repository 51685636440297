import styled from 'styled-components'
import { warmGrey } from 'components/baseColor'
import { IS_FRAME_MOBILE } from 'utils'
import { Text } from 'domains/PaymentStatus/styles'
import { IconSuccess as IconSuccessKit } from '@qlue/icon-kit'
import { Button } from 'components'

export const TableWrapper = styled.div`
  margin: 0 2rem;

  ${IS_FRAME_MOBILE} {
    margin: 0 0.5rem;
  }
`

export const Table = styled.table`
  width: 100%;
  margin: 2rem 0 1rem;
  border-collapse: collapse;

  ${IS_FRAME_MOBILE} {
    margin: 1rem 0 0.5rem;
  }
`

export const THeader = styled.thead`
  border-bottom: 1px solid ${warmGrey};
  padding-bottom: 1rem;
`

export const PriceSummaryWrapper = styled.div`
  border-top: 1px solid ${warmGrey};
  margin: 0 2rem 2rem;
  padding: 0.75rem 0;

  ${IS_FRAME_MOBILE} {
    margin: 0 0.5rem;
    padding: 0.5rem 0;
  }
`

export const Flex = styled.div`
  display: flex;
  margin: 0.75rem 0;
`

export const Value = styled(Text)`
  flex: 1 1 0;
`

export const IconSuccess = styled(IconSuccessKit)`
  display: block;
  margin: 1rem auto;
`

export const DownloadBtn = styled(Button)`
  display: block;
  margin: 1rem auto;
`

export const Key = styled(Text)`
  font-weight: 600;
  text-transform: uppercase;
`
