import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import { Timeline, MobileOnly, DesktopOnly, Shimmer } from 'components'
import { Wrapper, FooterWrapper, Link, Text } from './styles'
import API, { TransactionURL } from 'commons/API'
import { useSelector } from 'react-redux'
import { TRANSACTION_STATUS } from 'domains/BankTransferPending/constants'
import { PAYMENT_METHOD } from 'domains/Payment/constant'
import qs from 'query-string'
import { PaymentStatusSuccess, PaymentStatusUnSuccess } from './components'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

const propTypes = {
  location: PropTypes.object
}

const defaultProps = {
  location: {}
}

const PaymentStatusView = ({ location }) => {
  const queryParams = location.search
  const intl = useIntl()

  const transactionId = qs.parse(queryParams).transaction_id
  const [invoice, setInvoice] = useState({})
  const [transaction, setTransaction] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const { token: tokenDashboard, isLoading: isTokenLoading } = useSelector(({ tokenDashboard }) => tokenDashboard)

  useEffect(() => {
    if (tokenDashboard) {

      if (transactionId ) {
        API.get(`${TransactionURL.BaseURL}/${transactionId}`, {
          headers: { authorization: `Bearer ${tokenDashboard.token}` }
        })
          .then((response) => {
            const invoiceData = response.data.invoice
            const transactionData = response.data.transaction
            const paymentMethod = response.data.transaction.payment_method.name
            
            // set auto success for CC
            if (transactionData.transaction_status.name === TRANSACTION_STATUS.PENDING && paymentMethod === PAYMENT_METHOD.CREDIT_CARD) {
              transactionData.transaction_status.name = TRANSACTION_STATUS.SUCCESS
            }
            // prevent user navigate to payment status page if there is pending transaction when using VA
            else if (transactionData.transaction_status.name === TRANSACTION_STATUS.PENDING &&
                      paymentMethod === PAYMENT_METHOD.VIRTUAL_ACCOUNT ) {
              navigate('/payment/bank-transfer-pending')
            }
              
            setInvoice(invoiceData)
            setTransaction(transactionData)
            setIsLoading(false)

            localStorage.removeItem('transaction:qlue-saas-website')
            localStorage.removeItem('cart:qlue-saas-website')
          })
          .catch((error) => {
            alert(error.response.data.message)
            setIsLoading(false)
          })
      }
      else setIsLoading(false)
    } 
    else if(!isTokenLoading && !tokenDashboard) navigate('/pricing')
    
  }, [isTokenLoading, tokenDashboard, transactionId])

  const timelineList = {
    Checkout: {
      step: {
        onClick   : () => navigate('/checkout'),
        selected  : location.pathname.includes('/checkout'),
        completed : location.pathname.includes('/payment/status')
      },
      line: {
        active: location.pathname.includes('/payment/status')
      }
    },
    Payment: {
      step: {
        onClick   : () => navigate('/payment'),
        selected  : location.pathname.includes('/payment'),
        completed : location.pathname.includes('/payment/status')
      },
      line: {
        active: location.pathname.includes('/payment/status')
      }
    },
    Confirmation: {
      step: {
        onClick  : () => navigate('/payment/status'),
        selected : location.pathname.includes('/payment/status')
      }
    }
  }

  const paymentStatuSummary = () => {
    const status = transaction.transaction_status.name
    switch (status) {
      case TRANSACTION_STATUS.SUCCESS:
        return <PaymentStatusSuccess transaction={ transaction } invoice={ invoice } />
      case TRANSACTION_STATUS.FAILED:
        return <PaymentStatusUnSuccess transaction={ transaction } />
      case TRANSACTION_STATUS.CANCEL:
        return <PaymentStatusUnSuccess transaction={ transaction } />
      case TRANSACTION_STATUS.PENDING:
        return ''
    }
  }

  const billedName = () => {
    if (transaction.billing_information) return transaction.billing_information.name

    return transaction.account.company_name
  }

  return (
    <>
      <MobileOnly>
        <Timeline
          menu={{
            list   : timelineList,
            margin : {
              m1 : '0.5rem 0 0 0.5rem',
              m2 : '0.5rem 0 0 1.5rem',
              m3 : '0.5rem 0.5rem 0 0'
            }
          }}
        />
      </MobileOnly>

      <DesktopOnly>
        <div style={{ margin: '0 15rem' }}>
          <Timeline
            menu={{
              list   : timelineList,
              margin : {
                m1 : '0 0 0 1rem',
                m2 : '0 0 0 0.5rem',
                m3 : ''
              }
            }}
          />
        </div>
      </DesktopOnly>
      <Wrapper>
        { 
          isLoading 
            ? (
              <div style={{ padding: '0 1rem' }}>
                <Shimmer />
                <Shimmer />
                <Shimmer />
              </div>
            ) 
            : (
              <>
                <Text
                  style={{
                    display: transactionId && Object.keys(transaction).length > 0 ? 'none' : 'block'
                  }}
                  fontSize="1.75rem"
                  fontSizeMobile="1.25rem"
                  textCenter
                  bold
                >
                  { intl.formatMessage({ id: '9dd71a86f' }) }
                </Text>

                { transactionId && Object.keys(transaction).length > 0 ? (
                  <>
                    <Text bold textCenter fontSize="1.25rem" fontSizeMobile="0.875rem">
                      { 
                        transaction.transaction_status.name === TRANSACTION_STATUS.SUCCESS
                          ? intl.formatMessage({ id: 'be0aedd9d' }) 
                          : intl.formatMessage({ id: '49bd7e2ef' }) 
                      }
                    </Text>

                    <Text textCenter fontSize="1rem" margin="1rem 0 0">
                      { intl.formatMessage({ id: '79c484c22' }) }{ ' ' }{ billedName() }
                    </Text>

                    <Text textCenter fontSize="1rem" margin="0.75rem 0 0">
                      { intl.formatMessage({ id: '8e8e8ba92' }) }{ transaction.invoice_no }
                    </Text>

                    { paymentStatuSummary() }

                    <FooterWrapper>
                      <Text textCenter fontSize="1rem" fontWeight="500">
                        { intl.formatMessage({ id: '15af87701' }) }
                      </Text>

                      <Text textCenter fontSize="1rem" fontWeight="500">
                        { intl.formatMessage({ id: 'e73f317ca' }) } { ' ' }
                        <Link
                          href=""
                          onClick={ () => window.open('https://mail.google.com/mail/?view=cm&fs=1&to=support@qlue.id')
                          }
                        >
                          support@qlue.id
                        </Link>{ ' ' }
                        { intl.formatMessage({ id: 'e48604339' }) } <Link href="tel:+6281212333212">+62 812 1233 3212</Link>
                      </Text>
                    </FooterWrapper>
                  </>
                ) : null }
              </>
            ) }
      </Wrapper>
    </>
  )
}

PaymentStatusView.propTypes = propTypes
PaymentStatusView.defaultProps = defaultProps

export default PaymentStatusView
