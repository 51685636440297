import React from 'react'
import { IconFailed } from '@qlue/icon-kit'
import { Button } from 'components'
import { Text } from '../../styles'
import dayjs from 'dayjs'
import { useIntl } from 'react-intl'

const PaymentStatusFailed = ({ transaction }) => {

  const intl = useIntl()

  return(
    <>
      <IconFailed style={{ display: 'block', margin: '1rem auto' }}/>

      <Text
        danger
        textCenter
        fontWeight='600'
        fontSize='1rem'
      >
        { `${intl.formatMessage({ id: '2fc49d84c' })} ${ transaction.transaction_status.name }` }
      </Text>

      <Text
        textCenter
        fontSize='1rem'
        fontWeight='500'
        margin='1rem 0 2rem'
      >
        { `You’ve reached the time limit on ${dayjs(new Date()).format('DD MMMM YYYY')}` }
      </Text>

      <Button
        buttonType='warning'
        onClick={ () => window.open(transaction.invoice_pdf) }
        style={{ 
          margin  : '1rem auto', 
          display : 'block' 
        }}
      >
        { intl.formatMessage({ id: '9fab7e0ca' }) }
      </Button>
    </>
  )
}

export default PaymentStatusFailed
