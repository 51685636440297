import React from 'react'
import {
  Table, 
  TableWrapper, 
  THeader, 
  PriceSummaryWrapper, 
  Flex,
  Value,
  IconSuccess,
  DownloadBtn,
  Key
} from './styles'
import { Text } from '../../styles'
import { formatMoney } from 'utils'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { PaymentCalculation } from 'utils/paymentCalculation'

const propTypes = {
  transaction: PropTypes.shape({
    updated_at         : PropTypes.string,
    billing_start_date : PropTypes.string,
    billing_end_date   : PropTypes.string,
    currency           : PropTypes.object
  }),
  invoice: PropTypes.shape({
    accountPackage: PropTypes.shape({
      name     : PropTypes.string,
      quantity : PropTypes.number
    }),
    products  : PropTypes.array,
    amountDue : PropTypes.number,
    subTotal  : PropTypes.number,
    totalVAT  : PropTypes.number,
    VAT       : PropTypes.number
  })
}

const defaultProps = {
  transaction: {
    updated_at         : '',
    billing_start_date : '',
    billing_end_date   : '',
    currency           : {}
  },
  invoice: {
    accountPackage: {
      name     : '',
      quantity : 1
    },
    products  : [],
    amountDue : 0,
    subTotal  : 0,
    totalVat  : 0,
    VAT       : 0
  }
}

/**
 *
 * @param {Object} invoice consist of package detail price before discount.
 * @param {Object} transaction consist of payment transaction detail after discount.
**/

const PaymentStatusSuccess = ({
  transaction,
  invoice
}) => {

  const intl = useIntl()

  const Discount = () => {
    if(transaction.discount > 0) return (
      <Value
        success
        textAlign='right' 
      >
        { `- ${formatMoney(transaction.discount_amount, { currency: transaction.currency.name } ) }` }
      </Value>
    )

    return (
      <Value 
        fontSize="1rem" 
        textAlign='right' 
      >
        -
      </Value>
    )
  }

  return(
    <>
      <IconSuccess />

      <Text 
        success 
        textCenter 
        fontWeight='600' 
        fontSize='1rem'
      >
        { intl.formatMessage({ id: '5a7347b03' }) }
      </Text>

      <Text 
        textCenter 
        fontSize='1rem' 
        fontWeight='500' 
        margin='1rem 0 0'
      >
        { `${formatMoney(transaction.amount, { currency: transaction.currency.name })} ${ intl.formatMessage({ id: '4e30bfbf9' }) } ${dayjs(transaction.updated_at).format('DD MMMM YYYY')}` }
      </Text>

      <DownloadBtn
        buttonType='warning'
        onClick={ () => window.open(transaction.invoice_pdf) }
      >
        { intl.formatMessage({ id: '9fab7e0ca' }) }
      </DownloadBtn>

      <TableWrapper>
        <Table>
          <THeader>
            <tr>
              <th>
                <Text 
                  fontWeight='600' 
                  margin='0.75rem 0'
                >
                  { intl.formatMessage({ id: 'e8489d80b' }) }
                </Text>
              </th>

              <th>
                <Text fontWeight='600'>
                  { intl.formatMessage({ id: 'd8a5dd68a' }) }
                </Text>
              </th>

              <th>
                <Text 
                  fontWeight='600' 
                  textAlign='right'
                >
                  { intl.formatMessage({ id: '6f5ca8086' }) }
                </Text>
              </th>

              <th>
                <Text 
                  fontWeight='600' 
                  textAlign='right'
                >
                  { intl.formatMessage({ id: 'faee99848' }) }
                </Text>
              </th>
            </tr>
          </THeader>

          <tbody>
            <tr>
              <td>
                <Text 
                  fontWeight='600' 
                  margin='0.5rem 0'
                >
                  { invoice.accountPackage.name }
                </Text>
              </td>
              <td>
                <Text margin='0.5rem 0'>
                  { invoice.accountPackage.quantity }
                </Text>
              </td>
              <td>
                <Text
                  margin='0.5rem 0'
                  textAlign='right'
                >
                  { formatMoney(invoice.accountPackage.price, { currency: transaction.currency.name }) }
                </Text>
              </td>
              <td>
                <Text
                  margin='0.5rem 0'
                  textAlign='right'
                >
                  { formatMoney(invoice.accountPackage.price*invoice.accountPackage.quantity, { currency: transaction.currency.name }) }
                </Text>
              </td>

            </tr>

            <tr>
              <td>
                <Text 
                  shaded 
                  fontSize='0.875rem' 
                  margin='0.5rem 0'
                >
                  { `${dayjs(transaction.billing_start_date).format('DD MMM YYYY')} - ${dayjs(transaction.billing_end_date).format(
                    'DD MMM YYYY'
                  )}` }
                </Text>
              </td>
            </tr>

            { invoice.products.length > 0 
              ? 
              invoice.products.map((product) => (
                <tr key={ `row-${product.name}` }>
                  <td>
                    <Text margin='0.5rem 0'>
                      { product.name }
                    </Text>
                  </td>
                  <td>
                    <Text margin='0.5rem 0'>
                      { product.quantity }
                    </Text>
                  </td>
                  <td>
                    <Text 
                      margin='0.5rem 0'
                      textAlign='right' 
                    >
                      { formatMoney(product.price, { currency: transaction.currency.name }) }
                    </Text>
                  </td>
                  <td>
                    <Text 
                      margin='0.5rem 0'
                      textAlign='right' 
                    >
                      { formatMoney(product.total_price, { currency: transaction.currency.name }) }
                    </Text>
                  </td>
                </tr>
              )) 
              : null
            }
          </tbody>
        </Table>
      </TableWrapper>

      <PriceSummaryWrapper>
        <Flex>
          <Key>
            { intl.formatMessage({ id: '3e88df800' }) }
          </Key>
          <Value textAlign='right' >
            { formatMoney(invoice.subTotal, { currency: transaction.currency.name } ) }
          </Value>
        </Flex>

        <Flex>
          <Key>
            { intl.formatMessage({ id: '7cb5d176e' }) }
          </Key>
          <Discount />
        </Flex>

        <Flex>
          <Key>
            VAT ({ invoice.VAT * 110 }%)
          </Key>
          <Value textAlign='right' >
            { formatMoney(PaymentCalculation({
              discount : transaction.discount,
              subTotal : invoice.subTotal,
              vat      : invoice.VAT*110
            }).vatAmount, { currency: transaction.currency.name }) }
          </Value>
        </Flex>

        <Flex>
          <Key>
            { intl.formatMessage({ id: 'eb8239389' }) }
          </Key>
          <Value textAlign='right' >
            { formatMoney(transaction.amount, { currency: transaction.currency.name }) }
          </Value>
        </Flex>
      </PriceSummaryWrapper>
    </>
  )
}

PaymentStatusSuccess.propTypes = propTypes
PaymentStatusSuccess.defaultProps = defaultProps

export default PaymentStatusSuccess
