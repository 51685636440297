import styled, { css } from 'styled-components'
import { IS_FRAME_MOBILE, IS_FRAME_TABLET } from 'utils'
import { 
  darkBlueGrey, 
  emerald,
  warmGrey,
  coolGrey,
  errorValidation 
} from 'components/baseColor'

export const Wrapper = styled.div`
  margin: 1rem auto 3rem;
  border-radius: 8px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #bcbfc8;
  padding: 3rem 0;
  width: calc(100vw - 50em);
  max-width: 1600px;

  @media only screen and (min-width: 1024px) and (max-width: 1050px){
    width: calc(100vw - 35em);
  }

  ${IS_FRAME_TABLET} {
    margin: 1rem 1.5rem 3rem;
    padding: 2.5rem 0;
    width: unset;
  }

  ${IS_FRAME_MOBILE} {
    margin: 1rem 1rem 3rem;
    padding: 1rem 0;
    width: unset;
  }
`

export const Text = styled.div`
  color: ${({ color }) => color || darkBlueGrey};
  font-size: ${({ fontSize }) => fontSize || '0.875rem'};
  font-weight: ${({ fontWeight }) => fontWeight || 'normal'};
  letter-spacing: ${({ letterSpacing }) => letterSpacing || 'normal'};
  margin: ${({ margin }) => margin};  
  text-align: ${({ textAlign }) => textAlign || 'left'};

  ${IS_FRAME_MOBILE} {
    font-size: ${({ fontSizeMobile }) => fontSizeMobile || '0.75rem'};
    letter-spacing: ${({ letterSpacingMobile }) => letterSpacingMobile || 'normal'};
    margin: ${({ marginMobile }) => marginMobile};  

  } 

  ${({ bold }) => bold &&
    css`
      font-weight: bolder;
    `}

  ${({ textCenter }) => textCenter &&
    css`
      text-align: center;
    `}

    ${({ success }) => success &&
    css`
      color: ${ emerald } ;
    `}

    ${({ danger }) => danger &&
    css`
      color: ${ errorValidation } ;
    `}

    ${({ shaded }) => shaded &&
    css`
      color: ${coolGrey};
    `}
`

export const FooterWrapper = styled.div`
  border-top: 1px solid ${warmGrey};
  padding-top: 1.5rem;

  ${IS_FRAME_MOBILE} {
    padding: 0.75rem 0 1rem;
  }
`

export const Link = styled.a`
  color: #0186d5;
  cursor: pointer;
`
