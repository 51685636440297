import React from 'react'
import Layout from 'components/Layout'
import PaymentStatusView from 'domains/PaymentStatus'
import PropTypes from 'prop-types'

const propTypes = {
  location: PropTypes.object
}

const defaultProps = {
  location: {}
}

const PaymentStatus = ({ location }) => (
  <Layout>
    <PaymentStatusView location={ location }/>
  </Layout>
)

PaymentStatus.propTypes = propTypes
PaymentStatus.defaultProps = defaultProps

export default PaymentStatus
